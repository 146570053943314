'use client';

import { useTranslation } from 'app/i18n/client';
import { Button } from 'components/buttons/button';
import moment from 'moment';
import Image from 'next/image';
import { Fragment, useEffect, useState } from 'react';
import { ButtonType, INahdiError, MessageTypeEnum } from 'types/nahdi-errors';
import { replaceTextVariables } from 'utils/replaceTextVariables';
import 'moment/locale/ar';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { getTextByLang, LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import ContentfulLink from 'components/contentful-link';
import { getNahdiErrorsAnd404StatThroughApi } from 'lib/nahdi-errors';

interface MaintenanceModeProps {
  end_date?: string;
  start_date?: string;
  maintenance_mode?: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}

export const MaintenanceScheduleModal = ({
  end_date,
  start_date,
  maintenance_mode,
  language,
  region,
}: MaintenanceModeProps) => {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );

  //end_date = '2024-10-15T15:15:00';
  //maintenance_mode = 'true';
  //start_date = '2024-10-10T00:00:00';

  moment.locale(language);

  const startDate = moment(start_date);
  const endDate = moment(end_date);
  const currentDate = moment();

  const [openModal, setOpenModal] = useState(
    maintenance_mode === 'true' && currentDate.isBefore(startDate)
  );

  const [data, setData] = useState<INahdiError | null>(null);
  useEffect(() => {
    if (!openModal) {
      return;
    }

    const fetchData = async () => {
      const nahdiErrorsAnd404Stat = await getNahdiErrorsAnd404StatThroughApi({
        language: language,
        region: region,
      });
      setData(nahdiErrorsAnd404Stat);
    };

    fetchData();
  }, [language, openModal, region]);

  if (!openModal) return null;

  const template = data?.template.find(
    (temp) =>
      temp.component_properties.message_type ===
      MessageTypeEnum.SCHEDULED_MAINTENANCE
  );

  const fromText = t('from') as string;
  const toText = t('to') as string;

  const getFormattedDate = () => {
    const formatStart =
      language === 'ar'
        ? `dddd D MMMM YYYY [${fromText}] h:mm A`
        : `dddd, MMMM D, YYYY, [${fromText}] h:mm A`;

    const formattedStart = startDate.format(formatStart);
    const formatEnd = startDate.isSame(endDate, 'day')
      ? `[${toText}] h:mm A`
      : language === 'ar'
      ? `[${toText}] h:mm A dddd D MMMM YYYY`
      : `[${toText}] h:mm A, dddd, MMMM D, YYYY`;

    const formattedEnd = endDate.format(formatEnd);

    return `${formattedStart} ${formattedEnd}`;
  };

  if (!template) return null;

  const renderComponents = () => {
    return template.components?.map((comp) => (
      <Fragment key={comp.label}>
        {comp.button_type === ButtonType.Button ? (
          <Button
            style={{
              color: template.color_theme.cta_button_blue_text_color,
              backgroundColor:
                template.color_theme.cta_button_blue_background_color,
            }}
            onClick={() => setOpenModal(false)}
          >
            {comp.label}
          </Button>
        ) : (
          <ContentfulLink
            item={comp}
            style={{ color: template.color_theme.cta_hyperlink_text_color }}
            language={language}
            region={region}
          >
            {comp.label}
          </ContentfulLink>
        )}
      </Fragment>
    ));
  };
  return (
    <SecondaryOptionModal
      modalId='maintenance-mode'
      open={openModal}
      setOpen={setOpenModal}
      backgroundColor={template.color_theme.background_color}
      language={language}
      region={region}
    >
      {template.component_properties?.icon && (
        <div className='mx-auto w-[192px]'>
          <div className='relative pt-[89%]'>
            <Image
              src={template.component_properties.icon}
              fill
              alt='Scheduled Maintenance'
              className='w-full object-fill object-center'
            />
          </div>
        </div>
      )}
      {template.component_properties?.header && (
        <h2
          className='mb-2 mt-4 text-center text-xl font-semibold text-gray-dark lg:mb-4'
          style={{ color: template.color_theme.header_text_color }}
        >
          {getTextByLang(template.component_properties.header, language)}
        </h2>
      )}
      <div className='mx-auto mb-6 max-w-[301px] text-center text-custom-base text-gray-dark lg:max-w-[394px]'>
        {template.component_properties?.label && (
          <p
            className='mb-6 !leading-6'
            style={{ color: template.color_theme.label_text_color }}
          >
            {replaceTextVariables(
              getTextByLang(template.component_properties.label, language),
              { MAINTENANCE_DATE: getFormattedDate() }
            )}
          </p>
        )}
        {template.component_properties?.description && (
          <p
            className='!leading-6'
            style={{ color: template.color_theme.description }}
          >
            {getTextByLang(template.component_properties.description, language)}
          </p>
        )}
      </div>
      {renderComponents()}
    </SecondaryOptionModal>
  );
};

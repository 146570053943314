'use client';

import { useState } from 'react';
import ErxOrder from './erx-order';
import Order from './order';
import Register from './registration';
import WebPage from './web-page';

import { useAppSelector } from 'redux/hooks';
import { DownloadAppContentType } from 'types/cms/gss';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function DownloadApp({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const { appSettings } = useAppSelector((state) => state.cmsReducer);
  const downloadAppConfig =
    appSettings?.downloadAppConfig as DownloadAppContentType;
  const webPageContent = downloadAppConfig?.content?.find(
    (item) => item?.component_id === 'download-app-web-page'
  );
  const orderContent = downloadAppConfig?.content?.find(
    (item) => item?.component_id === 'download-app-order-confirmation'
  );
  const registerContent = downloadAppConfig?.content?.find(
    (item) => item?.component_id === 'download-app-registration-confirmatio'
  );
  const erxOrderContent = downloadAppConfig?.content?.find(
    (item) => item?.component_id === 'download-app-erx-order-confirmation'
  );

  return (
    <>
      {webPageContent?.enabled && (
        <WebPage
          webPageModalOpen={activeModal === 'webPage'}
          setWebPageModalOpen={(isOpen) => {
            if (isOpen) {
              setActiveModal('webPage');
            } else {
              setActiveModal(null);
            }
          }}
          content={webPageContent}
          language={language}
          region={region}
          rating={downloadAppConfig?.rating}
        />
      )}

      {orderContent?.enabled && (
        <Order
          orderModalOpen={activeModal === 'order'}
          setOrderModalOpen={(isOpen) => {
            if (isOpen) {
              setActiveModal('order');
            } else {
              setActiveModal(null);
            }
          }}
          content={orderContent}
          language={language}
          region={region}
          rating={downloadAppConfig?.rating}
        />
      )}

      {registerContent?.enabled && (
        <Register
          registerModalOpen={activeModal === 'register'}
          setRegisterModalOpen={(isOpen) => {
            if (isOpen) {
              setActiveModal('register');
            } else {
              setActiveModal(null);
            }
          }}
          content={registerContent}
          language={language}
          region={region}
          rating={downloadAppConfig?.rating}
        />
      )}

      {erxOrderContent?.enabled && (
        <ErxOrder
          erxOrderModalOpen={activeModal === 'erxOrder'}
          setErxOrderModalOpen={(isOpen) => {
            if (isOpen) {
              setActiveModal('erxOrder');
            } else {
              setActiveModal(null);
            }
          }}
          content={erxOrderContent}
          language={language}
          region={region}
          rating={downloadAppConfig?.rating}
        />
      )}
    </>
  );
}

'use client';

import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { setWishlist } from 'redux/features/wishlist';
import { getFetchWishlist } from 'lib/nahdi-middleware/account/wishlist/get-customer-wishlist';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { useAppDispatch } from 'redux/hooks';
import { COOKIE_CUSTOMER_TOKEN } from 'utils/constant-cookies';

export default function WishlistStore({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [cookies] = useCookies([COOKIE_CUSTOMER_TOKEN]);
  const customerToken = cookies?.customerToken;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const wishlistItems = async () => {
      const wishlistInfo = customerToken
        ? await getFetchWishlist(language, region)
        : undefined;
      if (wishlistInfo?.id) {
        dispatch(setWishlist(wishlistInfo));
      }
    };
    wishlistItems();
  }, [customerToken, dispatch, language, region]);

  return <></>;
}

// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
};
const initialState: TodoState = {
  modalOpen: false,
};
export const EzPillModal = createSlice({
  name: 'ezPillModal',
  initialState,
  reducers: {
    setEzPillModal: (state, action) => {
      state.modalOpen = action.payload;
    },
  },
});
export const { setEzPillModal } = EzPillModal.actions;
export default EzPillModal.reducer;

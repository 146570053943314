// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  defaultCard: {
    cardNumber: string;
    cardHolderName: string;
    cardExpiryDate: string;
    cardCVV: string;
    rememberMe: string;
    setDefault: boolean;
  };
  currentCard: {
    cardNumber: string;
    cardHolderName: string;
    cardExpiryDate: string;
    cardCVV: string;
    rememberMe: string;
    setDefault: boolean;
  };
  savedCardsInfo: {
    tokenName: string;
    cvv: string;
    public_hash: string;
  };
};
const initialState: TodoState = {
  defaultCard: {
    cardNumber: '',
    cardHolderName: '',
    cardExpiryDate: '',
    cardCVV: '',
    rememberMe: 'NO',
    setDefault: false,
  },
  currentCard: {
    cardNumber: '',
    cardHolderName: '',
    cardExpiryDate: '',
    cardCVV: '',
    rememberMe: 'NO',
    setDefault: false,
  },
  savedCardsInfo: {
    tokenName: '',
    cvv: '',
    public_hash: '',
  },
};
export const CreditCard = createSlice({
  name: 'Credit Card',
  initialState,
  reducers: {
    setDefaultCard: (state, action) => {
      state.defaultCard = action.payload;
    },
    setCurrentCard: (state, action) => {
      state.currentCard = action.payload;
    },
    setSavedCardsInfo: (state, action) => {
      state.savedCardsInfo = action.payload;
    },
  },
});
export const { setDefaultCard, setCurrentCard, setSavedCardsInfo } =
  CreditCard.actions;
export default CreditCard.reducer;

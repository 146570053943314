'use client';

import { useMediaQuery, desktopSize } from 'hooks/use-media-query';
import React, { Suspense, useEffect, useState } from 'react';
import { MobileWrapper, WebWrapper } from './mob-web';
import { LanguageCodesType, RegionCodesType } from 'utils';
interface IWrap {
  children: JSX.Element;
  language: LanguageCodesType;
  region: RegionCodesType;
}
export default function Wrapper({ children, language, region }: IWrap) {
  const [isClient, setIsClient] = useState(false);
  const matches = useMediaQuery(desktopSize);

  // Ensure this runs only on the client
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    // Return null or a loading state during SSR to avoid mismatch
    return null;
  }
  return (
    <Suspense>
      {matches ? (
        <WebWrapper language={language} region={region}>
          {children}
        </WebWrapper>
      ) : (
        <MobileWrapper language={language} region={region}>
          {children}
        </MobileWrapper>
      )}
    </Suspense>
  );
}

'use client';
import React, { ReactNode, useEffect } from 'react';
import WishlistStore from 'components/wishlist-store';
import { getNoSearchResults } from 'lib/cms/nahdi-middleware/no-search-result';
import { setnoSearchResultData } from 'redux/features/no-search-result';
import { getPLPContent } from 'lib/cms/nahdi-middleware/plp-content';
import { setPLPContent } from 'redux/features/plp-content';
import { initDefaultGuestAddress } from 'redux/features/customer';
import { useAppDispatch, useAppSelector } from './hooks';
import { getMiniBasketThunk } from './features/basket.reducer';
import { LanguageCodesType, RegionCodesType } from 'utils';

function InitialLoadRedux({
  children,
  language,
  region,
}: {
  children: ReactNode;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const dispatch = useAppDispatch();

  const { miniProfile } = useAppSelector((state) => state.customerReducer);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [searchResult, plpContent] = await Promise.all([
          getNoSearchResults(language, region),
          getPLPContent(language, region),
          dispatch(getMiniBasketThunk({ language, region })),
        ]);
        dispatch(setnoSearchResultData(searchResult));
        dispatch(setPLPContent(plpContent));
      } catch (ex) {
        ex;
      }
    };
    fetchData();
  }, [dispatch, language, region]);

  useEffect(() => {
    if (miniProfile) return;
    dispatch(initDefaultGuestAddress());
  }, [miniProfile, dispatch]);

  return (
    <>
      <WishlistStore language={language} region={region} />
      {children}
    </>
  );
}

export default InitialLoadRedux;

'use client';

import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import InitialLoadRedux from './initial-load-redux';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function ReduxProvider({
  children,
  language,
  region,
}: {
  children: React.ReactNode;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  return (
    <Provider store={store}>
      <InitialLoadRedux language={language} region={region}>
        {children}
      </InitialLoadRedux>
    </Provider>
  );
}

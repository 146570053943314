import { useEffect, useMemo, useState } from 'react';

// Components
import NeedHelpInformation from 'components/product/need-help-info';
import SecondaryOptionModal from 'components/secondary-option-modal';
import MightHelp from 'components/contact/might-help';
import { LanguageCodesType, RegionCodesType } from 'utils';

import type { ContentCTAType, NeedHelpType } from 'types/product';

export default function NeedHelpModal({
  contentCta,
  language,
  region,
  needHelpModalOpen,
  setNeedHelpModalOpen,
}: {
  contentCta: ContentCTAType;
  language: LanguageCodesType;
  region: RegionCodesType;
  needHelpModalOpen: boolean;
  setNeedHelpModalOpen: (value: boolean) => void;
}) {
  const [modalFaqItems, setModalFaqItems] = useState([]);
  const [modalFaqTitle, setModalFaqTitle] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalItems, setModalItems] = useState([]);

  const contentCtaCopy = useMemo(() => {
    if (!contentCta) return null;

    return JSON.parse(JSON.stringify(contentCta));
  }, [contentCta]);

  if (!contentCtaCopy) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const faqItems = [] as any;
    const items = [] as any;
    contentCtaCopy.component_properties.pdp_need_help
      .splice(1, contentCtaCopy.component_properties.pdp_need_help.length)
      .map((item: any) => {
        if (item['faq_item']) {
          faqItems.push(item);
          setModalFaqItems(faqItems);
        } else if (item['faq_title']) {
          setModalFaqTitle(item['faq_title'][language]);
        } else if (item['popup_title']) {
          setModalTitle(item['popup_title'][language]);
        } else if (item['popup_text']) {
          setModalText(item['popup_text'][language]);
        } else if (item.icon || item.label) {
          items.push(item);
          setModalItems(items);
        }
      });
  }, [contentCtaCopy.component_properties.pdp_need_help, language]);

  return (
    <SecondaryOptionModal
      modalId='need-help-modal'
      open={needHelpModalOpen}
      setOpen={setNeedHelpModalOpen}
      title={modalTitle}
      contentClassName='px-4 bg-light-gray-200'
      language={language}
      region={region}
    >
      <p className='mb-6 mt-4 text-custom-base text-gray-dark lg:mt-0'>
        {modalText}
      </p>
      <div className='grid grid-cols-3 gap-x-2'>
        {modalItems.map((item: NeedHelpType, index: number) => (
          <NeedHelpInformation
            key={index}
            item={item}
            language={language}
            region={region}
          />
        ))}
      </div>
      <div className='mt-8'>
        <h3 className='mb-2 text-xl'>{modalFaqTitle}</h3>
        <MightHelp items={modalFaqItems} language={language} region={region} />
      </div>
    </SecondaryOptionModal>
  );
}

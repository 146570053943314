import { getCmsCollection } from 'lib/nahdi-middleware';
import { ReportIssueMdType } from 'types/cms/nahdi-middleware';
import { LanguageCodesType, RegionCodesType } from 'utils';

export interface ResponseMdType<T> {
  fault: any;
  module_type: string;
  template: T[];
}

export const getReportAnIssuePageContent = async (
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  const data = (await getCmsCollection(
    'fetch-account-page',
    '',
    language,
    region
  )) as ResponseMdType<any>;

  const componentGroup = data?.template?.find(
    (item) =>
      item.component_properties.component_id ===
      'my-account-screens-container-group'
  );

  const reportAnIssue: ReportIssueMdType | undefined =
    componentGroup?.components?.find(
      (component: any) => component?.id === 'my-account-report-issue'
    );

  const emailField = reportAnIssue?.component_properties?.fields.find(
    (field) => field.id === 'EMAIL'
  );

  return {
    color_theme: {
      background_color:
        reportAnIssue?.color_theme?.background_color ?? '#FFFFFF',
      cta_button_background_color:
        reportAnIssue?.color_theme?.cta_button_background_color ?? '#2C6BA4',
      cta_button_disabled_background_color:
        reportAnIssue?.color_theme?.cta_button_disabled_background_color ??
        '#757575',
      cta_button_disabled_text_color:
        reportAnIssue?.color_theme?.cta_button_disabled_text_color ?? '#BDBDBD',
      cta_button_text_color:
        reportAnIssue?.color_theme?.cta_button_text_color ?? '#FFFFFF',
      field_border_color:
        reportAnIssue?.color_theme?.field_border_color ?? '#BDBDBD',
      field_placeholder_text_color:
        reportAnIssue?.color_theme?.field_placeholder_text_color ?? '#BDBDBD',
      field_value_text_color:
        reportAnIssue?.color_theme?.field_value_text_color ?? '#000000',
      header_text_color:
        reportAnIssue?.color_theme?.header_text_color ?? '#000000',
      info_background_color:
        reportAnIssue?.color_theme?.info_background_color ?? '#FFBB38',
      info_label_text_color:
        reportAnIssue?.color_theme?.info_label_text_color ?? '#424242',
      field_label_text_color:
        reportAnIssue?.color_theme?.field_label_text_color ?? '#424242',
    },
    component_properties: {
      cta_back_button: {
        icon: reportAnIssue?.component_properties?.cta_back_button?.icon ?? '',
        link: reportAnIssue?.component_properties?.cta_back_button?.link ?? '',
        link_type:
          reportAnIssue?.component_properties?.cta_back_button?.link_type ?? '',
      },
      fields: {
        emailPlaceholder: emailField?.placeholder?.[language] ?? '',
        emailLabel: emailField?.label?.[language] ?? '',
        feedbackPlaceholder:
          reportAnIssue?.component_properties?.fields.find(
            (field) => field.id === 'FEEDBACK'
          )?.placeholder?.[language] ?? '',
      },
      header: reportAnIssue?.component_properties?.header?.[language] ?? '',
      info_message: {
        icon: reportAnIssue?.component_properties?.info_message?.icon ?? '',
        label:
          reportAnIssue?.component_properties?.info_message?.label?.[
            language
          ] ?? '',
      },
      thank_you_screen: {
        cta_close_button: {
          icon:
            reportAnIssue?.component_properties?.thank_you_screen
              ?.cta_close_button?.icon ?? '',
          link:
            reportAnIssue?.component_properties?.thank_you_screen
              ?.cta_close_button?.link ?? '',
          link_type:
            reportAnIssue?.component_properties?.thank_you_screen
              ?.cta_close_button?.link_type ?? '',
        },
        cta_continue_shopping: {
          label:
            reportAnIssue?.component_properties?.thank_you_screen
              ?.cta_continue_shopping?.label?.[language] ?? '',
          link:
            reportAnIssue?.component_properties?.thank_you_screen
              ?.cta_continue_shopping?.link ?? '',
          link_type:
            reportAnIssue?.component_properties?.thank_you_screen
              ?.cta_continue_shopping?.link_type ?? '',
        },
        description:
          reportAnIssue?.component_properties?.thank_you_screen?.description?.[
            language
          ] ?? '',
        icon: reportAnIssue?.component_properties?.thank_you_screen?.icon ?? '',
        label:
          reportAnIssue?.component_properties?.thank_you_screen?.label?.[
            language
          ] ?? '',
      },
    },
    component_type: reportAnIssue?.component_type ?? '',
    cta_button: {
      button_type: reportAnIssue?.cta_button?.button_type ?? '',
      component_type: reportAnIssue?.cta_button?.component_type ?? '',
      label: reportAnIssue?.cta_button?.label ?? '',
      link: reportAnIssue?.cta_button?.link ?? '',
      link_type: reportAnIssue?.cta_button?.link_type ?? '',
      name: reportAnIssue?.cta_button?.name ?? '',
    },
    id: reportAnIssue?.id ?? '',
    name: reportAnIssue?.name ?? '',
  };
};

export type ReportAnIssuePageReturnType = Awaited<
  ReturnType<typeof getReportAnIssuePageContent>
>;

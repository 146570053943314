'use client';

import React, { useEffect, useState } from 'react';
import MobileContactHeader from '../cms/header/mobile-contact';
import Modal from '../modal';
import MobileModal from '../mobile-modal';
import { desktopSize, useMediaQuery } from 'hooks/use-media-query';
import ScanAndGoSwiper from './scan-and-go-swiper';
import {
  ScanAndGoPageReturnType,
  getScanAndGoPage,
  initialValue as scanAndGoPageInitialValue,
} from 'types/cms/get-scan-and-go';
import Image from 'next/image';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setScanAndGoModal } from 'redux/features/modals.reducer';
import { LanguageCodesType, RegionCodesType } from 'utils';

function ScanAndGoPopup({
  setIsOpen,
  isOpen,
  pageData,
  language,
  region,
}: {
  setIsOpen?: ((value: boolean) => void) | undefined;
  isOpen?: boolean;
  pageData?: ScanAndGoPageReturnType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPageData, setCurrentPageData] =
    useState<ScanAndGoPageReturnType>(pageData ?? scanAndGoPageInitialValue);

  const modalReducer = useAppSelector((state) => state.modals.scanAndGoModal);
  const isOpenModal = isOpen ? isOpen : modalReducer?.isOpen;
  const appDispatch = useAppDispatch();
  const closeModal = () => {
    appDispatch(setScanAndGoModal({ isOpen: false }));
  };
  const setIsOpenModal = setIsOpen ? setIsOpen : closeModal;

  useEffect(() => {
    const fetchPage = async () => {
      const pageData = await getScanAndGoPage(language, region);
      setCurrentPageData(pageData);
    };
    fetchPage();
  }, [language, region]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const isDesktopSize = useMediaQuery(desktopSize);
  const modalBody = (
    <div className='container-base relative bg-white py-4'>
      <div className='flex justify-center'>
        <div className='w-52'>
          <Image
            src={currentPageData?.widget.logo ?? ''}
            alt={'scan-and-go-logo'}
            width={1000}
            height={200}
          />
        </div>
      </div>
      <ScanAndGoSwiper
        pageData={currentPageData}
        language={language}
        region={region}
      />
    </div>
  );
  if (!isLoaded) return null;

  return (
    <div>
      {isDesktopSize ? (
        <Modal
          titleContainerClassName='!mb-0'
          titlePosition='center'
          modalId='scan-and-go-modal'
          open={isOpenModal}
          setOpen={setIsOpenModal}
          title={currentPageData?.widget.label}
          backgroundColor='white'
          language={language}
          region={region}
        >
          {modalBody}
        </Modal>
      ) : (
        <MobileModal
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          style='full'
          hideCloseButton={true}
          contentClassName={'!p-0'}
        >
          <MobileContactHeader
            title={currentPageData?.widget.label ?? ''}
            titleClassName='normal-case'
            componentClassName='md:hidden'
            language={language}
            region={region}
          />
          {modalBody}
        </MobileModal>
      )}
    </div>
  );
}
export default ScanAndGoPopup;

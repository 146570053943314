import { createSlice } from '@reduxjs/toolkit';
import { PLPContentType } from 'types/cms/plp-content';
type PLPContentState = {
  plpContentData: PLPContentType | undefined;
};
const initialState: PLPContentState = {
  plpContentData: undefined,
};
export const PLPContent = createSlice({
  name: 'PLPContent',
  initialState,
  reducers: {
    setPLPContent: (state, action) => {
      state.plpContentData = action.payload;
    },
  },
});
export const { setPLPContent } = PLPContent.actions;
export default PLPContent.reducer;

'use client';
import { useRouter } from 'next/navigation';
import { AssistantIcon, GoBackIcon } from 'components/icon';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';
import { useState } from 'react';
import NeedHelpModal from 'components/need-help-modal';
import { CheckoutContentType } from 'types/cms/nahdi-middleware';
import { ContentCTAType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function MobileContactHeader({
  language,
  region,
  title,
  titleClassName,
  componentClassName,
  textColor,
  backgroundColor,
  callAction,
  icon,
  additionalContent,
  customBackFunction,
  checkoutContent,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
  title: string;
  titleClassName?: string;
  componentClassName?: string;
  textColor?: string;
  backgroundColor?: string;
  callAction?: boolean;
  icon?: string;
  additionalContent?: React.ReactNode;
  customBackFunction?: () => void;
  checkoutContent?: CheckoutContentType;
}) {
  const router = useRouter();
  const [needHelpModalOpen, setNeedHelpModalOpen] = useState(false);
  const needHelpComponent = checkoutContent?.template?.find(
    (item) => item.id === 'pdp-cta'
  ) as unknown;

  return (
    <>
      {needHelpComponent && (
        <NeedHelpModal
          contentCta={needHelpComponent as ContentCTAType}
          language={language}
          region={region}
          needHelpModalOpen={needHelpModalOpen}
          setNeedHelpModalOpen={setNeedHelpModalOpen}
        />
      )}
      <div
        className={`h-16 bg-white px-4 lg:hidden ${componentClassName}`}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className='flex h-full w-full items-center justify-between'>
          {icon ? (
            <Image
              src={icon}
              alt={title}
              width={20}
              height={16}
              onClick={() =>
                customBackFunction ? customBackFunction() : router.back()
              }
            />
          ) : (
            <GoBackIcon
              onClick={() =>
                customBackFunction ? customBackFunction() : router.back()
              }
              className='has-color fill-current text-blue rtl:rotate-180'
            />
          )}
          <h5
            className={twMerge('line-clamp-1 px-3 text-xl', titleClassName)}
            style={{ color: textColor }}
          >
            {title}
          </h5>
          {callAction ? (
            <button onClick={() => setNeedHelpModalOpen(true)}>
              <AssistantIcon className='has-color h-5 fill-current text-primary-blue' />
            </button>
          ) : additionalContent ? (
            <div>{additionalContent}</div>
          ) : (
            <div className='h-4 w-5'></div>
          )}
        </div>
      </div>
    </>
  );
}

'use client';

import React, { useEffect, useState } from 'react';
import { Button } from 'components/buttons/button';
import { submitReportAnIssue } from 'lib/api';
import MobileModal from 'components/mobile-modal';
import Modal from 'components/modal';
import { desktopSize, useMediaQuery } from 'hooks/use-media-query';
import Input from 'components/input/input';
import * as Yup from 'yup';
import { useTranslation } from 'app/i18n/client';
import Image from 'next/image';
import { ReportAnIssuePageReturnType } from 'lib/cms/nahdi-middleware/report-an-issue';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { CustomCSSProperties } from 'types/prop-types';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { useAppSelector } from 'redux/hooks';
import ContentfulLink from 'components/contentful-link';
import clsx from 'clsx';

function ReportAnIssueForm({
  reportAnIssuePage,
  language,
  region,
  closeModal,
}: {
  reportAnIssuePage: ReportAnIssuePageReturnType | null;
  language: LanguageCodesType;
  region: RegionCodesType;
  closeModal?: () => void;
}) {
  const [pending, setPending] = useState(false);
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'report-an-issue'
  );

  const userEmail = useAppSelector(
    (state) => state.customerReducer.miniProfile?.email
  );

  useEffect(() => {
    if (userEmail) setEmail(userEmail);
  }, [userEmail]);

  const schemaForm = Yup.object().shape({
    email: Yup.string()
      .email(ts('email_format_error'))
      .required(ts('required_email')),
    feedback: Yup.string().required(ts('required_feedback')),
  });

  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isSuccess, setIsSucess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationMessages, setValidationMessages] = useState({
    email: '',
    feedback: '',
  });
  const validateForm = async () => {
    const errors = {
      email: '',
      feedback: '',
    };

    return await schemaForm
      .validate({ email, feedback }, { abortEarly: false })
      .then(() => {
        return true;
      })
      .catch((err) => {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            errors[error.path as keyof typeof errors] = error.message;
          });
          return false;
        }
      })
      .finally(() => setValidationMessages(errors));
  };
  const isDesktopSize = useMediaQuery(desktopSize);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const validation = await validateForm();
    if (!validation) return;

    const payload = {
      email,
      text: feedback,
    };
    setPending(true);
    try {
      const submitResult = await submitReportAnIssue(payload, language, region);
      if (submitResult.status === 200) {
        setIsSucess(true);
        setFeedback('');
      } else {
        setErrorMessage(submitResult.message);
      }
    } catch {
      setErrorMessage(ts('feedback_failed'));
    } finally {
      setPending(false);
    }
  };

  const thankYouModal = (
    <div className='flex flex-col items-center'>
      <Image
        src={
          reportAnIssuePage?.component_properties?.thank_you_screen.icon ?? ''
        }
        alt={ts('confirmation.order_confirmed')}
        width={0}
        height={0}
        className='mx-auto h-20 w-20'
      />
      <h3 className='mb-4 text-2xl max-lg:mt-6 lg:mt-4'>
        {reportAnIssuePage?.component_properties?.thank_you_screen.label}
      </h3>
      <p className='text-center text-custom-base max-lg:mb-6 max-lg:px-12 lg:mb-[14px] lg:text-gray-dark'>
        {reportAnIssuePage?.component_properties?.thank_you_screen
          ?.description ?? ''}
      </p>
      <ContentfulLink
        className='w-full py-4 max-lg:mb-5'
        item={{
          link: reportAnIssuePage?.component_properties?.thank_you_screen
            ?.cta_continue_shopping.link,
          link_type:
            reportAnIssuePage?.component_properties?.thank_you_screen
              ?.cta_continue_shopping.link_type,
        }}
        language={language}
        region={region}
      >
        <Button>
          {
            reportAnIssuePage?.component_properties?.thank_you_screen
              ?.cta_continue_shopping?.label
          }
        </Button>
      </ContentfulLink>
    </div>
  );

  return (
    <div>
      <form
        className={clsx('max-lg:bg-white max-lg:p-4', isSuccess && 'hidden')}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Input
          type='text'
          name='email'
          id='email'
          containerClassname='lg:mt-4'
          placeholder={
            reportAnIssuePage?.component_properties?.fields?.emailPlaceholder ??
            ''
          }
          label={
            reportAnIssuePage?.component_properties?.fields?.emailLabel ||
            ts('email_address')
          }
          variant={'primary'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          labelClassname='text-gray'
          inputClassname='custom-placeholder'
          value={email}
          autocomplete='email'
          errorMessage={validationMessages.email}
          inputStyles={{
            input_text_border_color:
              reportAnIssuePage?.color_theme?.field_border_color,
            input_text_color:
              reportAnIssuePage?.color_theme?.field_value_text_color,
            input_placeholder_text_color:
              reportAnIssuePage?.color_theme?.field_placeholder_text_color,
            input_label_text_color:
              reportAnIssuePage?.color_theme?.field_label_text_color,
          }}
          language={language}
          region={region}
        />
        <textarea
          rows={6}
          cols={30}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder={
            reportAnIssuePage?.component_properties?.fields
              ?.feedbackPlaceholder ?? ''
          }
          className='custom-placeholder peer mt-4 block w-full appearance-none rounded-md border border-gray-400 pb-2 ps-4 pt-3 text-custom-base text-gray-900 placeholder-light-gray shadow-none outline-none focus:border-primary-blue-600 focus:ring-0 focus-visible:ring-0'
          style={
            {
              borderColor: reportAnIssuePage?.color_theme?.field_border_color,
              color: reportAnIssuePage?.color_theme?.field_value_text_color,
              '--placeholder-color':
                reportAnIssuePage?.color_theme?.field_placeholder_text_color,
            } as CustomCSSProperties
          }
        />
        {validationMessages.feedback && (
          <span className='mt-1 text-custom-xs text-red'>
            {validationMessages.feedback}
          </span>
        )}
        <Button
          className='custom-disabled mt-4'
          loading={pending}
          type='submit'
          disabled={!feedback.length}
          style={
            {
              background:
                reportAnIssuePage?.color_theme?.cta_button_background_color,
              color: reportAnIssuePage?.color_theme.cta_button_text_color,
              '--btn-disabled-color':
                reportAnIssuePage?.color_theme?.cta_button_disabled_text_color,
              '--btn-disabled-bg-color':
                reportAnIssuePage?.color_theme
                  ?.cta_button_disabled_background_color,
            } as CustomCSSProperties
          }
        >
          {reportAnIssuePage?.cta_button?.label ?? ''}
        </Button>
        {errorMessage && (
          <span className='mt-1 text-custom-xs text-red'>{errorMessage}</span>
        )}
      </form>

      {isDesktopSize ? (
        <Modal
          modalId='success-card-modal'
          open={isSuccess}
          setOpen={setIsSucess}
          title={''}
          closeButtonImage={
            reportAnIssuePage?.component_properties?.thank_you_screen
              ?.cta_close_button?.icon
          }
          language={language}
          region={region}
          dynamicModalCloseHandler={closeModal}
        >
          {thankYouModal}
        </Modal>
      ) : (
        <MobileModal
          isOpen={isSuccess}
          setIsOpen={setIsSucess}
          style='bottom'
          backgroundColor='#F4F8FA'
          closeButtonClassName='top-6'
          contentClassName='px-8'
          closeButtonImage={
            reportAnIssuePage?.component_properties?.thank_you_screen
              ?.cta_close_button?.icon
          }
          dynamicModalCloseHandler={closeModal}
        >
          {thankYouModal}
        </MobileModal>
      )}
    </div>
  );
}

export default ReportAnIssueForm;

import { StarIcon } from 'components/icon';
import Image from 'next/image';
import { Button } from 'components/buttons/button';
import { Link } from 'components/link';
import { DownloadAppItemType } from 'types/cms/gss';
import AppCodes from 'components/checkout/app-codes';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function Content({
  isMobile,
  content,
  language,
  rating,
  region,
  handleCloseModal,
}: {
  isMobile: boolean;
  content: DownloadAppItemType | undefined;
  language: LanguageCodesType;
  rating: string;
  region: RegionCodesType;
  handleCloseModal: () => void;
}) {
  return (
    <>
      {isMobile ? (
        <div className='p-3'>
          <div className='flex space-x-3 rtl:space-x-reverse'>
            <div className='flex h-[60px] w-[77px] items-center justify-center rounded-xl border border-gray-dark bg-white'>
              {content?.image && (
                <Image
                  src={content?.image}
                  alt={content?.desktop_title?.[language] || ''}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-[37px] w-[39px]'
                />
              )}
            </div>

            <div className='flex flex-col space-y-1'>
              <div className='flex items-center justify-between'>
                {content?.title?.[language] && (
                  <span className='text-custom-lg font-semibold'>
                    {content?.title?.[language]}
                  </span>
                )}
                <div className='flex items-center space-x-2 rtl:space-x-reverse'>
                  <div className='flex items-center space-x-1 rtl:space-x-reverse'>
                    {Array.from({ length: parseFloat(rating) }).map(
                      (_, index) => (
                        <StarIcon key={'star' + index} className={`h-4 w-4`} />
                      )
                    )}
                  </div>
                  <span className='text-custom-sm text-gray'>{rating}</span>
                </div>
              </div>

              {content?.descripiton?.[language] && (
                <p className='text-custom-base text-gray-dark'>
                  {content?.descripiton[language]}
                </p>
              )}
            </div>
          </div>
          <div className='mt-3 flex flex-col space-y-2'>
            <Link
              href={content?.download_button_url || ''}
              style={{
                backgroundColor: '#000',
              }}
              className='flex h-[50px] items-center justify-center space-x-3 rounded-lg py-0 shadow-md rtl:space-x-reverse'
            >
              {content?.download_button_icon && (
                <Image
                  src={content?.download_button_icon}
                  alt={content?.download_button_text?.[language] || ''}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-6 w-5'
                />
              )}

              <span className='font-semibold text-white'>
                {content?.download_button_text?.[language]}
              </span>
            </Link>
            <Button
              appearance='outline'
              className='h-10 py-0 !text-custom-sm shadow-md'
              onClick={handleCloseModal}
            >
              {content?.continue_button_text?.[language]}
            </Button>
          </div>
        </div>
      ) : (
        <AppCodes language={language} region={region} />
      )}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
type NuhdeekPoints = {
  nuhdeekPoints: number | undefined;
  isLoading: boolean;
};
const initialState: NuhdeekPoints = {
  nuhdeekPoints: undefined,
  isLoading: false,
};
export const nuhdeekPointsSlice = createSlice({
  name: 'nuhdeekPoints',
  initialState,
  reducers: {
    setNuhdeekPoints: (state, action) => {
      state.nuhdeekPoints = action.payload;
    },
    setIsLoadingNuhdeek: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export const { setNuhdeekPoints, setIsLoadingNuhdeek } =
  nuhdeekPointsSlice.actions;
export default nuhdeekPointsSlice.reducer;

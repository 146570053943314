'use client';

import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { useEffect } from 'react';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { DownloadAppItemType } from 'types/cms/gss';
import Content from './content';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function WebPage({
  webPageModalOpen,
  setWebPageModalOpen,
  content,
  language,
  region,
  rating,
}: {
  webPageModalOpen: boolean;
  setWebPageModalOpen: (value: boolean) => void;
  content: DownloadAppItemType | undefined;
  language: LanguageCodesType;
  region: RegionCodesType;
  rating: string;
}) {
  const isMobile = useMediaQuery(mobileSize);

  useEffect(() => {
    setWebPageModalOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCloseModal() {
    setWebPageModalOpen(false);
  }

  return (
    <SecondaryOptionModal
      modalId='web-page-modal'
      open={webPageModalOpen}
      setOpen={setWebPageModalOpen}
      title={isMobile ? '' : content?.desktop_title?.[language]}
      titlePosition='center'
      hideCloseButtonOnMobile
      containerClassName={
        isMobile ? 'z-[110] rounded-none rounded-t-none shadow-2xl' : ''
      }
      dynamicModalCloseHandler={handleCloseModal}
      contentClassName={isMobile ? '!p-0' : ''}
      reactPortalRemove
      language={language}
      region={region}
    >
      <Content
        isMobile={isMobile}
        content={content}
        language={language}
        region={region}
        rating={rating}
        handleCloseModal={handleCloseModal}
      />
    </SecondaryOptionModal>
  );
}

'use client';

import React, { useEffect } from 'react';
import { HomePageProductCarouselType } from 'types/cms/product-carousel';
import { useGetExperienceProducts } from 'hooks/use-experience';
import { setNoAvailableProducts } from 'redux/features/alternative-modal';
import { useAppDispatch } from 'redux/hooks';

import ProductCarousel from 'components/cms/banners/product-carousel-banner/product-carousel';
import { disableGlobalConfig } from 'utils/disable-global-config';
import { useAppSelector } from 'redux/hooks';
import { LanguageCodesType, RegionCodesType } from 'utils';

function DyProductCarousel({
  language,
  region,
  data,
  ipAddress,
  componentIndex,
  zoneId,
  swiper = true,
  availableOnly,
  setLoading,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
  data: HomePageProductCarouselType;
  ipAddress: string;
  componentIndex: number | null;
  zoneId: string | undefined;
  swiper?: boolean;
  availableOnly?: boolean;
  setLoading?: (loading: boolean) => void;
}) {
  const appDispatch = useAppDispatch();

  const { appSettings } = useAppSelector((state) => state.cmsReducer);
  const dynamicYieldEnable = disableGlobalConfig({
    settings: appSettings?.functionalityConfig?.dynamicYieldSettings,
  });

  const [products] = useGetExperienceProducts(
    data.product_id_list,
    dynamicYieldEnable ? data.campaign_id : '',
    ipAddress,
    zoneId,
    language,
    region,
    dynamicYieldEnable,
    availableOnly
  );

  useEffect(() => {
    if (setLoading) {
      setLoading(true);
      if (products?.length === 0) {
        appDispatch(setNoAvailableProducts(true));
        setLoading(false);
      } else {
        appDispatch(setNoAvailableProducts(false));
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, setLoading]);

  return (
    <ProductCarousel
      algoliaType={false}
      products={products!}
      availableOnly={data?.available_only}
      fadeLast={data?.color_theme?.slider_fade_effect}
      componentIndex={componentIndex || 0}
      swiper={swiper}
      language={language}
      region={region}
    />
  );
}

export default DyProductCarousel;

'use client';

import { useEffect } from 'react';
import { setAppSettings } from './features/cms.reducer';
import { AppSettings } from 'types/cms/gss';
import { MiniBasketType } from 'types/cart';
import { getMiniBasketThunk } from './features/basket.reducer';
import { useAppDispatch } from './hooks';
import { LanguageCodesType, RegionCodesType } from 'utils';

function UseLoadSystemSettings({
  appSettings,
  miniBasket,
  language,
  region,
}: {
  appSettings: AppSettings;
  miniBasket?: MiniBasketType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setAppSettings(appSettings));
    if (miniBasket) {
      dispatch(
        getMiniBasketThunk.fulfilled(miniBasket, '', { language, region })
      );
    }
  }, [dispatch, appSettings, miniBasket, language, region]);

  return null;
}

export default UseLoadSystemSettings;

'use client';

import React, { useState } from 'react';
import { Button } from 'components/buttons/button';
import MobileModal from 'components/mobile-modal';
import Modal from 'components/modal';
import { desktopSize, useMediaQuery } from 'hooks/use-media-query';
import Image from 'components/image';
import { Link } from 'components/link';
import { ScanAndGoPageReturnType } from 'types/cms/get-scan-and-go';
import BaseSwiper from 'components/swiper/base-swiper';
import MobileContactHeader from 'components/cms/header/mobile-contact';
import clsx from 'clsx';
import { LanguageCodesType, RegionCodesType } from 'utils';

function ScanAndGoSwiper({
  pageData,
  language,
  region,
}: {
  pageData: ScanAndGoPageReturnType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isOpenDownloadPage, setIsOpenDownloadPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sliderIsEnd, setSliderIsEnd] = useState(false);
  const isDesktopSize = useMediaQuery(desktopSize);

  return (
    <>
      <div className={clsx(isOpenDownloadPage ? 'hidden' : 'block')}>
        <BaseSwiper
          className={`scan-and-go-swiper relative overflow-hidden`}
          swiperProps={{
            onSlideChangeTransitionEnd: (value) => {
              setPageNumber(value.activeIndex);
              setSliderIsEnd(value.isEnd);
            },
            pagination: true,
            slidesPerView: 1,
            breakpoints: {
              320: {
                spaceBetween: 1,
              },
              720: {
                spaceBetween: 12,
              },
            },
            navigation: {
              nextEl: '.custom-carousel-right-button',
            },
          }}
        >
          {pageData.widget.onboarding_screen.map((item, index: number) => {
            return (
              <div
                key={`scan-and-go-onboarding-${index}`}
                className={`container-base relative mx-auto flex w-full flex-col justify-center max-lg:h-[600px] lg:h-[390px]`}
                style={{
                  backgroundImage: item.background_image_url,
                  backgroundColor: item.backgroundColor,
                }}
              >
                <div className='flex justify-center'>
                  <div className='max-lg:h-[350px] max-lg:w-[350px] lg:h-[200px] lg:w-[200px]'>
                    <Image
                      src={item.center_image_url}
                      alt={'scan-and-go-logo'}
                      width={200}
                      height={200}
                      onlyImage={true}
                    />
                  </div>
                </div>
                <h2
                  className='mt-8 text-center text-2xl'
                  style={{ color: item.header_text_color }}
                >
                  {item.header_text}
                </h2>
                <span
                  style={{ color: item.description_text_color }}
                  className='mt-4 text-center text-custom-base'
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
              </div>
            );
          })}
        </BaseSwiper>
        {sliderIsEnd ? (
          <Button
            className='mt-6'
            disabled={false}
            onClick={() => setIsOpenDownloadPage(true)}
            style={{
              backgroundColor: pageData.color_theme.cta_button_color,
              color: pageData.color_theme.cta_button_text_color,
            }}
          >
            {pageData.widget.onboarding_screen?.[pageNumber]?.cta_button.label}
          </Button>
        ) : (
          <Button
            style={{
              backgroundColor: pageData.color_theme.cta_button_color,
              color: pageData.color_theme.cta_button_text_color,
            }}
            className='custom-carousel-right-button mt-6 disabled:bg-blue disabled:text-white'
          >
            {pageData.widget.onboarding_screen?.[pageNumber]?.cta_button.label}
          </Button>
        )}
      </div>
      {isDesktopSize ? (
        <Modal
          titlePosition='center'
          titleContainerClassName='!mb-0'
          modalId='scan-and-go-modal-modal'
          open={isOpenDownloadPage}
          setOpen={setIsOpenDownloadPage}
          title={pageData?.confirmation_popup.header}
          backgroundColor='white'
          language={language}
          region={region}
        >
          <div className='mt-6 flex justify-center'>
            <Image
              src={pageData.confirmation_popup.desktop.ios_qr.qr_image}
              alt='ios-qr-code'
              width={100}
              height={100}
            />
          </div>
          <p className='mt-4 text-center'>
            {pageData.confirmation_popup.desktop.ios_qr.label}
          </p>
          <div className='mt-4 flex justify-center'>
            <Link href={pageData.confirmation_popup.desktop.ios_qr.app_link}>
              <Image
                src={pageData.confirmation_popup.desktop.ios_qr.store_image}
                alt='ios-qr-download-link-image'
                width={145}
                height={50}
              />
            </Link>
          </div>
          <div className='mt-6 flex justify-center'>
            <Image
              src={pageData.confirmation_popup.desktop.play_store_qr.qr_image}
              alt='play-store-qr-code'
              width={100}
              height={100}
            />
          </div>
          <p className='mt-4 text-center'>
            {pageData.confirmation_popup.desktop.play_store_qr.label}
          </p>
          <div className='mt-4 flex justify-center'>
            <Link
              href={pageData.confirmation_popup.desktop.play_store_qr.app_link}
            >
              <Image
                src={
                  pageData.confirmation_popup.desktop.play_store_qr.store_image
                }
                alt='play-store-qr-download-link-image'
                width={145}
                height={50}
              />
            </Link>
          </div>
        </Modal>
      ) : (
        <MobileModal
          isOpen={isOpenDownloadPage}
          setIsOpen={setIsOpenDownloadPage}
          style='full'
          backgroundColor='#F4F8FA'
          hideCloseButton={true}
          contentClassName={'!p-0'}
        >
          <MobileContactHeader
            title={pageData.confirmation_popup.mobile.title}
            titleClassName='normal-case'
            componentClassName='md:hidden'
            language={language}
            region={region}
          />
          <div className='flex justify-center'>
            <Image
              src={pageData.confirmation_popup.mobile.image}
              alt='mobile-confirmation-image'
              width={243}
              height={461}
            />
          </div>
          <h2 className='mt-8 text-center text-2xl'>
            {pageData.confirmation_popup.mobile.header}
          </h2>
          <p className='mt-4 px-10 text-center text-custom-base'>
            {pageData.confirmation_popup.mobile.description}
          </p>
          <div className='mt-6 flex justify-center'>
            <Image
              src={pageData.confirmation_popup.mobile.store_image}
              alt='mobile-store-image'
              width={145}
              height={50}
            />
          </div>
        </MobileModal>
      )}
    </>
  );
}
export default ScanAndGoSwiper;

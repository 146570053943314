'use client';

import clsx from 'clsx';
import { CloseIcon, GoBackIcon } from 'components/icon';
import ReactPortal from 'components/react-portal';
import { useTranslation } from 'app/i18n/client';
import { setIsWrapperOpen } from 'redux/features/locator.reducer';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

interface IWrap {
  children: JSX.Element;
  language: LanguageCodesType;
  region: RegionCodesType;
}

export function WebWrapper({ children, language, region }: IWrap) {
  const isVisible = useAppSelector((state) => state.locator.isWrapperOpen);
  const dispatch = useAppDispatch();
  if (!isVisible) return null;
  return (
    <ReactPortal wrapperId='pharmacy-locator'>
      <div className='fixed left-0 right-0 top-0 z-[99] h-screen w-full bg-black/75 backdrop-blur-sm'></div>
      <div
        className={clsx(
          'fixed bottom-0 left-0 right-0 top-0 z-[99]',
          'no-scrollbar !m-0 flex min-h-screen items-center justify-center overflow-y-auto md:m-auto'
        )}
      >
        <div className='relative mb-1.5 lg:w-[900px] xl:w-[1000px] 2xl:w-[1200px]'>
          <div className='flex w-full justify-end pb-4'>
            <button
              onClick={() => dispatch(setIsWrapperOpen(false))}
              className={clsx(
                'flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-none bg-white p-2 outline-none'
              )}
            >
              <CloseIcon className='scale-75' />
            </button>
          </div>
          <div className='relative flex w-full rounded-lg lg:bg-white'>
            {children}
          </div>
        </div>
      </div>
    </ReactPortal>
  );
}

export function MobileWrapper({ children, language, region }: IWrap) {
  const isVisible = useAppSelector((state) => state.locator.isWrapperOpen);
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'store-finder'
  );
  const dispatch = useAppDispatch();
  return (
    <div
      className={clsx(
        'fixed bottom-0 z-[999] flex h-screen w-full flex-col overflow-y-auto bg-light-gray-200 transition-all duration-500',
        `${
          isVisible
            ? 'ltr:right-0 rtl:left-0'
            : 'ltr:-right-[4000px] rtl:-left-[4000px]'
        }`
      )}
    >
      <div className='bg-white p-4 lg:hidden'>
        <div className='flex items-center justify-between'>
          <GoBackIcon
            onClick={() => dispatch(setIsWrapperOpen(false))}
            className='has-color fill-current text-blue'
          />
          <h1 className='line-clamp-1 px-3 text-xl capitalize'>
            {t?.('near_by')}
          </h1>
          <div></div>
        </div>
      </div>
      <div className='relative flex-1'>{children}</div>
    </div>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import todoReducer from './features/slice-example';
import shippingAddressModalReducer from './features/shipping-address-modal';
import noSearchResultReducer from './features/no-search-result';
import plpContentReducer from './features/plp-content';
import addressReducer from './features/address.reducer';
import customerReducer from './features/customer';
import basketReducer from './features/basket.reducer';
import ModalsReducer from './features/modals.reducer';
import storeAvailabilityReducer from './features/store-availability';
import checkoutReducer from './features/checkout';
import creditCardReducer from './features/credit-card';
import wishlistReducer from './features/wishlist';
import stcPAYReducer from './features/stc-pay';
import alternativeModalReducer from './features/alternative-modal';
import adherenceModalReducer from './features/erx/adherence-modal';
import erxOrderSetData from './features/erx/order-set-data';
import paymentTypeModalReducer from './features/erx/payment-type-modal';
import ezPillModalReducer from './features/erx/ez-pill-modal';
import bundleModalReducer from './features/bundle-products';
import presceriptionModalReducer from './features/presceription-modal';
import locatorReducer from './features/locator.reducer';
import nuhdeekPointsReducer from './features/nuhdeek-points.reducer';
import cmsReducer from './features/cms.reducer';
import searchQueryReducer from './features/searchQuerySlice';

export const store = configureStore({
  reducer: {
    todoReducer,
    shippingAddressModalReducer,
    noSearchResultReducer,
    plpContentReducer,
    addressReducer,
    customerReducer,
    basket: basketReducer,
    modals: ModalsReducer,
    storeAvailabilityReducer,
    checkoutReducer,
    creditCardReducer,
    wishlistReducer,
    stcPAYReducer,
    alternativeModalReducer,
    adherenceModalReducer,
    erxOrderSetData,
    paymentTypeModalReducer,
    ezPillModalReducer,
    bundleModalReducer,
    presceriptionModalReducer,
    locator: locatorReducer,
    nuhdeekPointsReducer,
    cmsReducer,
    searchQuery: searchQueryReducer,
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
});

'use client';

import React, { useEffect, useState } from 'react';
import MobileContactHeader from './cms/header/mobile-contact';
import ReportAnIssueForm from './report-an-issue-form/report-an-issue-form';
import Modal from './modal';
import MobileModal from './mobile-modal';
import { desktopSize, useMediaQuery } from 'hooks/use-media-query';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setReportAnIssueModal } from 'redux/features/modals.reducer';
import {
  ReportAnIssuePageReturnType,
  getReportAnIssuePageContent,
} from 'lib/cms/nahdi-middleware/report-an-issue';
import { LanguageCodesType, RegionCodesType } from 'utils';
import Image from 'next/image';

function ReportAnIssuePopup({
  setIsOpen,
  isOpen,
  language,
  region,
}: {
  setIsOpen?: ((value: boolean) => void) | undefined;
  isOpen?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const isDesktopSize = useMediaQuery(desktopSize);
  const [reportAnIssuePageData, setReportAnIssuePageData] =
    useState<ReportAnIssuePageReturnType | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const reportIssuePage = await getReportAnIssuePageContent(
        language,
        region
      );
      setReportAnIssuePageData(reportIssuePage);
    };

    fetchData();
  }, [language, region]);

  const modalReducer = useAppSelector(
    (state) => state.modals.reportAnIssueModal
  );
  const isOpenModal = isOpen ? isOpen : modalReducer?.isOpen;
  const appDispatch = useAppDispatch();
  const closeModal = () => {
    appDispatch(setReportAnIssueModal({ isOpen: false }));
  };

  const setIsOpenModal = setIsOpen ? setIsOpen : closeModal;

  const modalBody = (
    <div className='container-base py-4'>
      <div
        className='mb-4 flex items-center space-x-2 rounded-lg bg-yellow/20 px-3 py-3 rtl:space-x-reverse'
        style={{
          backgroundColor:
            reportAnIssuePageData?.color_theme?.info_background_color,
        }}
      >
        {reportAnIssuePageData?.component_properties?.info_message?.icon && (
          <div className='size-4 shrink-0'>
            <Image
              src={reportAnIssuePageData.component_properties.info_message.icon}
              alt={
                reportAnIssuePageData?.component_properties?.info_message?.label
              }
              width={16}
              height={16}
              className='w-full'
            />
          </div>
        )}

        <span
          className='text-custom-xs font-semibold text-gray-dark'
          style={{
            color: reportAnIssuePageData?.color_theme?.info_label_text_color,
          }}
        >
          {reportAnIssuePageData?.component_properties?.info_message?.label}
        </span>
      </div>
      <ReportAnIssueForm
        reportAnIssuePage={reportAnIssuePageData}
        language={language}
        region={region}
        closeModal={closeModal}
      />
    </div>
  );

  const bgColor = reportAnIssuePageData?.color_theme?.background_color;
  const headerColor = reportAnIssuePageData?.color_theme?.header_text_color;
  return (
    <div>
      {isDesktopSize ? (
        <Modal
          titleContainerClassName='!mb-0'
          modalId='report-an-issue-modal'
          open={isOpenModal}
          setOpen={setIsOpenModal}
          title={reportAnIssuePageData?.component_properties?.header ?? ''}
          language={language}
          region={region}
          backgroundColor={bgColor}
          titleStyle={{
            color: headerColor,
          }}
        >
          {modalBody}
        </Modal>
      ) : (
        <MobileModal
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          style='full'
          backgroundColor={bgColor}
          hideCloseButton
          contentClassName={'!p-0'}
        >
          <MobileContactHeader
            title={reportAnIssuePageData?.component_properties?.header ?? ''}
            titleClassName='normal-case'
            componentClassName='md:hidden'
            language={language}
            region={region}
            textColor={headerColor}
            icon={
              reportAnIssuePageData?.component_properties?.cta_back_button?.icon
            }
          />
          {modalBody}
        </MobileModal>
      )}
    </div>
  );
}

export default ReportAnIssuePopup;

import { getCmsCollection } from 'lib/nahdi-middleware';
import { ScanAndGoMdType } from './nahdi-middleware';
import { LanguageCodesType, RegionCodesType } from 'utils';

export const initialValue = {
  widget: {
    component_type: '',
    label: '',
    logo: '',
    onboarding_screen: [],
  },
  color_theme: {
    cta_button_color: '',
    cta_button_text_color: '',
    screen_indicator_current_color: '',
    screen_indicator_current_normal: '',
  },
  properties: {
    cta_back_button_icon: '',
    cta_button_icon: '',
  },
  confirmation_popup: {
    header: '',
    description: '',
    desktop: {
      ios_qr: {
        app_link: '',
        label: '',
        qr_image: '',
        store_image: '',
      },
      play_store_qr: {
        app_link: '',
        label: '',
        qr_image: '',
        store_image: '',
      },
    },
    mobile: {
      header: '',
      image: '',
      store_image: '',
      description: '',
      title: '',
    },
  },
  module_type: '',
};

export const getScanAndGoPage = async (
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  const data: ScanAndGoMdType = await getCmsCollection(
    'fetch-onboarding-scan-and-go',
    '',
    language,
    region
  );

  return {
    widget: {
      component_type:
        data?.template?.[0]?.widgets?.[0]?.component_type ??
        initialValue.widget.component_type,
      label:
        data?.template?.[0]?.widgets?.[0]?.label?.[language] ??
        data?.template?.[0]?.widgets?.[0]?.label?.en ??
        initialValue.widget.label,
      logo: data?.template?.[0]?.widgets?.[0]?.logo ?? initialValue.widget.logo,
      onboarding_screen:
        data?.template?.[0]?.widgets?.[0]?.onboarding_screen?.map((screen) => ({
          background_image_url: screen?.background_image_url ?? '',
          backgroundColor: screen?.color_theme?.background_color ?? '#ffffff',
          sub_header_text_color:
            screen?.color_theme?.sub_header_text_color ?? '#000000',
          header_text_color:
            screen?.color_theme?.header_text_color ?? '#000000',
          description_text_color:
            screen?.color_theme?.description_text_color ?? '#000000',
          center_image_url: screen?.center_image_url ?? '',
          cta_button: {
            label:
              screen?.cta_button?.label?.[language] ??
              screen?.cta_button?.label?.en ??
              '',
            link: screen?.cta_button?.link ?? '',
            link_type: screen?.cta_button?.link_type ?? '',
          },
          cta_back_button: {
            link: screen?.cta_back_button?.link ?? '',
            link_type: screen?.cta_back_button?.link_type ?? '',
          },
          description: screen?.description?.[language] ?? '',
          header_text: screen?.header_text?.[language] ?? '',
          sub_header_text: screen?.sub_header_text?.[language] ?? '',
          video_url: screen?.video_url ?? '',
        })) ?? initialValue.widget.onboarding_screen,
    },
    color_theme: {
      cta_button_color:
        data?.template?.[0]?.color_theme?.cta_button_color ??
        initialValue.color_theme.cta_button_color,
      cta_button_text_color:
        data?.template?.[0]?.color_theme?.cta_button_text_color ??
        initialValue.color_theme.cta_button_text_color,
      screen_indicator_current_color:
        data?.template?.[0]?.color_theme?.screen_indicator_current_color ??
        initialValue.color_theme.screen_indicator_current_color,
      screen_indicator_current_normal:
        data?.template?.[0]?.color_theme?.screen_indicator_current_normal ??
        initialValue.color_theme.screen_indicator_current_normal,
    },
    properties: {
      cta_back_button_icon:
        data?.template?.[0]?.properties?.cta_back_button_icon ??
        initialValue.properties.cta_back_button_icon,
      cta_button_icon:
        data?.template?.[0]?.properties?.cta_button_icon ??
        initialValue.properties.cta_button_icon,
    },
    confirmation_popup: {
      header:
        data?.template?.[0]?.web_confirmation_popup?.header?.[language] ??
        initialValue.confirmation_popup.header,
      description:
        data?.template?.[0]?.web_confirmation_popup?.description?.[language] ??
        initialValue.confirmation_popup.description,
      desktop: {
        ios_qr: {
          app_link:
            data?.template?.[0]?.web_confirmation_popup?.desktop?.ios_qr
              ?.app_link ??
            initialValue.confirmation_popup.desktop.ios_qr.app_link,
          label:
            data?.template?.[0]?.web_confirmation_popup?.desktop?.ios_qr
              ?.label ?? initialValue.confirmation_popup.desktop.ios_qr.label,
          qr_image:
            data?.template?.[0]?.web_confirmation_popup?.desktop?.ios_qr
              ?.qr_image ??
            initialValue.confirmation_popup.desktop.ios_qr.qr_image,
          store_image:
            data?.template?.[0]?.web_confirmation_popup?.desktop?.ios_qr
              ?.store_image ??
            initialValue.confirmation_popup.desktop.ios_qr.store_image,
        },
        play_store_qr:
          data?.template?.[0]?.web_confirmation_popup?.desktop?.play_store_qr ??
          initialValue.confirmation_popup.desktop.play_store_qr,
      },
      mobile: {
        header:
          data?.template?.[0]?.web_confirmation_popup?.mobile?.header?.[
            language
          ] ?? initialValue.confirmation_popup.mobile.header,
        image:
          data?.template?.[0]?.web_confirmation_popup?.mobile?.image ??
          initialValue.confirmation_popup.mobile.image,
        store_image:
          data?.template?.[0]?.web_confirmation_popup?.mobile?.store_image ??
          initialValue.confirmation_popup.mobile.store_image,
        description:
          data?.template?.[0]?.web_confirmation_popup?.mobile?.description?.[
            language
          ] ?? initialValue.confirmation_popup.mobile.description,
        title:
          data?.template?.[0]?.web_confirmation_popup?.mobile?.title?.[
            language
          ] ?? initialValue.confirmation_popup.mobile.title,
      },
    },
    module_type: data?.module_type ?? initialValue.module_type,
  };
};

export type ScanAndGoPageReturnType = Awaited<
  ReturnType<typeof getScanAndGoPage>
>;

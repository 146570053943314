'use client';

import { useEffect, useState } from 'react';

export default function ImpersonationLogoutHandler() {
  const [initialState, setInitialState] = useState<string | null>(null);

  useEffect(() => {
    const initialValue = localStorage.getItem('cit');
    setInitialState(initialValue);
  }, []);

  useEffect(() => {
    const onStorage = () => {
      if (localStorage.getItem('cit') !== initialState) {
        window.removeEventListener('storage', onStorage);
        window.location.href = '/';
      }
    };

    if (initialState) {
      window.addEventListener('storage', onStorage);
    }

    return () => {
      if (initialState) {
        window.removeEventListener('storage', onStorage);
      }
    };
  }, [initialState]);

  return null;
}

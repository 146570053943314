'use client';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import NextImage, { ImageProps } from 'next/image';
import React, { ReactNode } from 'react';

type CustomImageProps = Omit<ImageProps, 'src'> & {
  src: string | StaticImport | undefined;
  className?: string;
  height: number;
  width: number;
  onlyImage?: boolean;
  children?: ReactNode;
};

function Image(props: CustomImageProps) {
  const { onlyImage = false, src, width, height, ...rest } = props;

  if (!src) {
    return <div style={{ width, height }}></div>;
  }

  const imageElement = (
    <NextImage {...rest} src={src} width={width} height={height} />
  );

  return onlyImage ? (
    imageElement
  ) : (
    <div style={{ width, height }}>{imageElement}</div>
  );
}

export default Image;

'use client';

import { useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { toast } from 'react-toastify';
import { useTranslation } from 'app/i18n/client';
import Notifications from '.';

import type { ToastPosition } from 'react-toastify';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';

const queries = {
  passwordChanged: {
    componentProps: {
      closeIcon: false,
      text: 'password_changed',
    },
    toastProps: {
      autoClose: 10000,
      hideProgressBar: true,
      theme: 'success',
      position: 'top-center',
      className: 'query-notification',
    },
  },
  registered: {
    componentProps: {
      closeIcon: false,
      text: 'registered',
    },
    toastProps: {
      autoClose: 10000,
      hideProgressBar: true,
      theme: 'success',
      position: 'top-center',
      className: 'query-notification',
    },
  },
  impersonate: {
    componentProps: {
      closeIcon: false,
      text: 'impersonate',
    },
    toastProps: {
      autoClose: 10000,
      hideProgressBar: true,
      theme: 'success',
      position: 'top-center',
      className: 'query-notification',
    },
  },
  impUnsatisfied: {
    componentProps: {
      closeIcon: false,
      text: 'imp_unsatisfied',
    },
    toastProps: {
      autoClose: 10000,
      hideProgressBar: true,
      theme: 'error',
      position: 'top-center',
      className: 'query-notification',
    },
  },
};

export default function QueryNotifications({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'query-notifications'
  );

  const [pending, setPending] = useState(true);
  useEffect(() => {
    if (!pending) {
      searchParams.forEach((value, key) => {
        handleQuery({ value, key });
      });
    } else {
      setPending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pending]);

  function getQueryData(key: string) {
    const res = queries[key as keyof typeof queries];
    if (res?.componentProps?.text) {
      res.componentProps.text = t(res.componentProps.text) as string;
    }
    return res;
  }

  function handleQuery({ value, key }: { value: string; key: string }) {
    if (key && queries[key as keyof typeof queries] && value === 'true') {
      const data = getQueryData(key);
      toast(<Notifications componentProps={data.componentProps} />, {
        ...data.toastProps,
        position: data.toastProps.position as ToastPosition,
      });
      deleteQuery(key);
    }
  }

  function deleteQuery(key: string) {
    const params = new URLSearchParams(searchParams.toString());
    params.delete(key);
    router.replace(`${pathname}?${params.toString()}`);
  }

  return null;
}

'use client';

import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { COOKIE_POPUP_REGISTRATION_CONFIRMATION } from 'utils/constant-cookies';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { usePathname } from 'next/navigation';
import { loginHref, registerHref } from 'utils/route-urls';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { DownloadAppItemType } from 'types/cms/gss';
import Content from './content';
import { LanguageCodesType, RegionCodesType } from 'utils';
import SetDownloadAppCookie from 'utils/download-app-cookie';

export default function Register({
  registerModalOpen,
  setRegisterModalOpen,
  content,
  language,
  region,
  rating,
}: {
  registerModalOpen: boolean;
  setRegisterModalOpen: (value: boolean) => void;
  content: DownloadAppItemType | undefined;
  language: LanguageCodesType;
  region: RegionCodesType;
  rating: string;
}) {
  const currentRoute = usePathname();
  const isMobile = useMediaQuery(mobileSize);
  const [cookies] = useCookies([COOKIE_POPUP_REGISTRATION_CONFIRMATION]);
  const registrationConfirmationCookie =
    cookies?.popup_registrationConfirmation;
  const [modalClose, setModalClose] = useState(false);

  useEffect(() => {
    if (registrationConfirmationCookie && !modalClose) {
      setRegisterModalOpen(true);
    }
  }, [registrationConfirmationCookie, modalClose, setRegisterModalOpen]);

  useEffect(() => {
    if (
      (!modalClose && currentRoute.includes(registerHref)) ||
      (!modalClose && currentRoute.includes(loginHref))
    ) {
      setRegisterModalOpen(false);
    }
  }, [currentRoute, modalClose, setRegisterModalOpen]);

  function handleCloseModal() {
    setModalClose(true);
    setRegisterModalOpen(false);

    SetDownloadAppCookie({
      cookie: COOKIE_POPUP_REGISTRATION_CONFIRMATION,
    });
  }

  return (
    <SecondaryOptionModal
      modalId='registration-confirmation-modal'
      open={registerModalOpen}
      setOpen={setRegisterModalOpen}
      title={isMobile ? '' : content?.desktop_title?.[language]}
      titlePosition='center'
      hideCloseButtonOnMobile
      containerClassName={
        isMobile ? 'z-[110] rounded-none rounded-t-none shadow-2xl' : ''
      }
      dynamicModalCloseHandler={handleCloseModal}
      contentClassName={isMobile ? '!p-0' : ''}
      reactPortalRemove
      language={language}
      region={region}
    >
      <Content
        isMobile={isMobile}
        content={content}
        language={language}
        region={region}
        rating={rating}
        handleCloseModal={handleCloseModal}
      />
    </SecondaryOptionModal>
  );
}

import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/app/[lng]/globals.css");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/app/[lng]/instant-search-provider.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/alternative-modal/index.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/cms/cookie-privacy/cookie-privacy-bar.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/customer-redux-feeder.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/download-app/index.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/impersonation-logout-handler.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/maintenance-schedule-modal/index.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/my-cart/confirm-modal.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/notifications/query.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/pharmacy-locator/locator.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/pharmacy-locator/wrapper.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/report-an-issue-popup.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/components/scan-and-go/scan-and-go-popup.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/@next+third-parties@14.2.9_next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@_cb3q2qn46uszbxyja4j3g6arbi/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/@next+third-parties@14.2.9_next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@_cb3q2qn46uszbxyja4j3g6arbi/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/@next+third-parties@14.2.9_next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@_cb3q2qn46uszbxyja4j3g6arbi/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/[lng]/montserrat-fonts.ts\",\"import\":\"\",\"arguments\":[{\"adjustFontFallback\":false,\"variable\":\"--montserrat-font\",\"src\":[{\"path\":\"../../public/fonts/Montserrat/Montserrat-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat/Montserrat-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat/Montserrat-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat/Montserrat-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat/Montserrat-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/[lng]/tajawal-fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--tajawal-font\",\"src\":[{\"path\":\"../../public/fonts/Tajawal/Tajawal-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Tajawal/Tajawal-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Tajawal/Tajawal-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Tajawal/Tajawal-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"tajawal\"}");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/react-toastify@10.0.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/node_modules/.pnpm/react-toastify@10.0.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/redux/provider.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_develop/redux/useLoadSystemSettings.tsx")
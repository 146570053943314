import { useCookies } from 'react-cookie';
import { createCookieConfig } from './cookie';

export default function SetDownloadAppCookie({
  cookie,
  date,
  days,
}: {
  cookie: string;
  date?: string;
  days?: number;
}) {
  const [, setCookie] = useCookies([cookie]);

  const currentDateTime = date ? new Date(date) : new Date();
  const expiryDate = new Date(
    currentDateTime.getTime() + (days || 10) * 24 * 60 * 60 * 1000
  );
  setCookie(cookie, expiryDate, createCookieConfig({ expires: expiryDate }));
}

'use client';

import { useState } from 'react';
import Image from 'next/image';
import { Button } from 'components/buttons/button';
import { useTranslation } from 'app/i18n/client';
import { showToast } from 'lib/toast';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setMapModal,
  selectAddressModalWithPermission,
} from 'redux/features/address.reducer';
import { useCookies } from 'react-cookie';
import { COOKIE_CUSTOMER_TOKEN } from 'utils/constant-cookies';
import { closeGlobalDeliveryWarningModal } from 'redux/features/modals.reducer';
import { GlobalIcon } from 'components/icons/global-icon';
import { updateDeliveryMode } from 'redux/features/customer';
import { moveGlobalItemsToWishlistThunk } from 'redux/features/wishlist';
import { LanguageCodesType, RegionCodesType } from 'utils';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function ConfirmModal({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { isOpen, type } = useAppSelector(
    (state) => state.modals.globalDeliveryWarningModal
  );
  const closeModal = () => {
    appDispatch(closeGlobalDeliveryWarningModal());
  };
  const [loadingAction, setLoadingAction] = useState(false);
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'cart'
  );
  const appDispatch = useAppDispatch();
  const [cookies] = useCookies([COOKIE_CUSTOMER_TOKEN]);
  const { miniProfile, addressList, guest } = useAppSelector(
    (state) => state.customerReducer
  );

  async function handleMoveGlobalItemsToWishlist() {
    setLoadingAction(true);
    appDispatch(
      selectAddressModalWithPermission({
        value: false,
        language,
        region,
      })
    );
    if (!cookies?.customerToken) {
      setLoadingAction(false);
      return;
    }
    const result = await appDispatch(
      moveGlobalItemsToWishlistThunk({ language, region })
    );
    if (result.meta.requestStatus === 'fulfilled') {
      closeModal();
    } else {
      showToast(ts('operation_error'), 'error');
    }
    setLoadingAction(false);
    closeModal();
  }

  function handleContinueHomeDelivery() {
    appDispatch(
      selectAddressModalWithPermission({
        value: false,
        language,
        region,
      })
    );
    closeModal();
  }

  function handleSwitchToHomeDelivery() {
    const addresses = miniProfile
      ? addressList?.length > 0
      : guest?.addressList && guest?.addressList?.length > 0;
    appDispatch(
      addresses
        ? selectAddressModalWithPermission({
            value: true,
            language,
            region,
          })
        : setMapModal(true)
    );
    closeModal();
  }

  async function handleSwitchToStorePickup() {
    appDispatch(updateDeliveryMode('pickup'));
    appDispatch(
      selectAddressModalWithPermission({
        value: false,
        language,
        region,
      })
    );
    await handleMoveGlobalItemsToWishlist();
  }

  let title = '';
  let description = '';
  let description2 = '';
  let firstButtonText = '';
  let secondButtonText = '';
  let firstButtonOnClick: () => void;
  let secondButtonOnClick: () => void;

  switch (type) {
    case 'only-home-delivery':
      title = ts('confirm_modal.only_home_delivery');
      description = ts('confirm_modal.only_home_delivery_description');
      firstButtonText = ts('confirm_modal.switch_home_delivery');
      secondButtonText = ts('confirm_modal.continue_store_pickup');
      firstButtonOnClick = handleSwitchToHomeDelivery;
      secondButtonOnClick = () => void handleMoveGlobalItemsToWishlist();
      break;
    case 'confirm':
      title = ts('confirm_modal.please_confirm');
      description = ts('confirm_modal.confirm_description');
      description2 = ts('confirm_modal.confirm_description_2');
      firstButtonText = ts('confirm_modal.continue_home_delivery');
      secondButtonText = ts('confirm_modal.switch_store_pickup');
      firstButtonOnClick = handleContinueHomeDelivery;
      secondButtonOnClick = () => void handleSwitchToStorePickup();
      break;
  }

  return (
    <SecondaryOptionModal
      modalId='confirm-modal'
      open={isOpen}
      setOpen={closeModal}
      title={title}
      titlePosition='center'
      modalClassname='z-[98]'
      backgroundBlurClassName='z-[98]'
      titleContainerClassName='!justify-center'
      language={language}
      region={region}
    >
      <div className='flex flex-col items-center'>
        <Image
          src='/assets/gifs/warning.gif'
          alt=''
          width={0}
          height={0}
          className='mb-3 h-[102px] w-[102px]'
        />
        <GlobalIcon className='w-24' />
        <p className='mt-6 text-center text-custom-base text-gray-dark max-lg:mb-3'>
          <span>{description}</span>
          {description2 && <span className='mt-6 block'>{description2}</span>}
        </p>

        <div className='mt-3 space-y-3 lg:mt-6'>
          <Button
            onClick={() => {
              firstButtonOnClick();
            }}
          >
            {firstButtonText}
          </Button>
          <Button
            appearance='outline'
            onClick={() => {
              secondButtonOnClick();
            }}
            loading={loadingAction ? true : undefined}
          >
            {secondButtonText}
          </Button>
        </div>
      </div>
    </SecondaryOptionModal>
  );
}

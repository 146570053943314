'use client';

import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { COOKIE_POPUP_ERX_ORDER_CONFIRMATION } from 'utils/constant-cookies';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { DownloadAppItemType } from 'types/cms/gss';
import Content from './content';
import { LanguageCodesType, RegionCodesType } from 'utils';
import SetDownloadAppCookie from 'utils/download-app-cookie';

export default function ErxOrder({
  erxOrderModalOpen,
  setErxOrderModalOpen,
  content,
  language,
  region,
  rating,
}: {
  erxOrderModalOpen: boolean;
  setErxOrderModalOpen: (value: boolean) => void;
  content: DownloadAppItemType | undefined;
  language: LanguageCodesType;
  region: RegionCodesType;
  rating: string;
}) {
  const isMobile = useMediaQuery(mobileSize);
  const [cookies] = useCookies([COOKIE_POPUP_ERX_ORDER_CONFIRMATION]);
  const erxOrderConfirmationCookie = cookies?.popup_erxOrderConfirmation;
  const [modalClose, setModalClose] = useState(false);

  useEffect(() => {
    const currentDateTime = new Date();
    const cookieExpiryDate = new Date(erxOrderConfirmationCookie);
    const tenDaysBeforeExpiry = new Date(
      cookieExpiryDate.getTime() - 10 * 24 * 60 * 60 * 1000
    );

    if (
      erxOrderConfirmationCookie &&
      currentDateTime >= tenDaysBeforeExpiry &&
      currentDateTime < cookieExpiryDate &&
      !modalClose
    ) {
      setErxOrderModalOpen(true);
    }
  }, [erxOrderConfirmationCookie, modalClose, setErxOrderModalOpen]);

  function handleCloseModal() {
    setModalClose(true);
    setErxOrderModalOpen(false);

    SetDownloadAppCookie({
      cookie: COOKIE_POPUP_ERX_ORDER_CONFIRMATION,
      days: 20,
    });
  }

  return (
    <SecondaryOptionModal
      modalId='erx-confirmation-modal'
      open={erxOrderModalOpen}
      setOpen={setErxOrderModalOpen}
      title={isMobile ? '' : content?.desktop_title?.[language]}
      titlePosition='center'
      hideCloseButtonOnMobile
      containerClassName={
        isMobile ? 'z-[110] rounded-none rounded-t-none shadow-2xl' : ''
      }
      dynamicModalCloseHandler={handleCloseModal}
      contentClassName={isMobile ? '!p-0' : ''}
      reactPortalRemove
      language={language}
      region={region}
    >
      <Content
        isMobile={isMobile}
        content={content}
        language={language}
        region={region}
        rating={rating}
        handleCloseModal={handleCloseModal}
      />
    </SecondaryOptionModal>
  );
}

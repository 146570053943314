'use client';

import { useTranslation } from 'app/i18n/client';
import Image from 'next/image';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function AppCodes({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'checkout'
  );

  return (
    <div className='space-y-8 divide-y divide-light-gray-100 lg:space-y-12'>
      <div className='flex flex-col items-center space-y-4'>
        <Image
          src='/assets/images/checkout/scan-modal/scan-ios.png'
          alt={t('confirmation.scan_ios') as string}
          width={0}
          height={0}
          sizes='100vw'
          className='h-[100px] w-[100px]'
        />
        <span className='text-custom-base'>{t('confirmation.scan_ios')}</span>
        <Image
          src='/assets/images/checkout/scan-modal/app-store.png'
          alt={t('confirmation.download_app') as string}
          width={0}
          height={0}
          sizes='100vw'
          className='h-[50px] w-[145px]'
        />
      </div>

      <div className='flex flex-col items-center space-y-4 pt-3 lg:pt-6'>
        <Image
          src='/assets/images/checkout/scan-modal/scan-android.png'
          alt={t('confirmation.scan_android') as string}
          width={0}
          height={0}
          sizes='100vw'
          className='h-[100px] w-[100px]'
        />
        <span className='text-custom-base'>
          {t('confirmation.scan_android')}
        </span>
        <Image
          src='/assets/images/checkout/scan-modal/google-play.png'
          alt={t('confirmation.download_app') as string}
          width={0}
          height={0}
          sizes='100vw'
          className='h-[50px] w-[145px]'
        />
      </div>
    </div>
  );
}

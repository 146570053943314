// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
};
const initialState: TodoState = {
  modalOpen: false,
};
export const AdherenceModal = createSlice({
  name: 'adherenceModal',
  initialState,
  reducers: {
    setAdherenceModal: (state, action) => {
      state.modalOpen = action.payload;
    },
  },
});
export const { setAdherenceModal } = AdherenceModal.actions;
export default AdherenceModal.reducer;

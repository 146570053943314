// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';

type TodoState = {
  Refill_Items: {
    item_code: string;
    item_name: string;
    item_qty: string;
  }[];
  ORDER_TYPE: string;
  selectedMember: any;
  isEzPillSwitch: any;
};

const initialState: TodoState = {
  Refill_Items: [],
  ORDER_TYPE: '',
  selectedMember: null,
  isEzPillSwitch: null,
};

export const OrderSetData = createSlice({
  name: 'Order Set Data',
  initialState,
  reducers: {
    setRefillItems: (state, action) => {
      state.Refill_Items = action.payload;
    },
    updateRefillItems: (state, action) => {
      state.Refill_Items = state.Refill_Items.map((item) =>
        item.item_code === action.payload.item_code ? action.payload : item
      );
    },
    setOrderType: (state, action) => {
      state.ORDER_TYPE = action.payload;
    },
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setIzEzpiiSwitch: (state, action) => {
      state.isEzPillSwitch = action.payload;
    },
  },
});

export const {
  setRefillItems,
  updateRefillItems,
  setOrderType,
  setSelectedMember,
  setIzEzpiiSwitch,
} = OrderSetData.actions;
export default OrderSetData.reducer;

// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  modalOpen: boolean;
};
const initialState: TodoState = {
  modalOpen: false,
};
export const PaymentTypeModal = createSlice({
  name: 'paymentTypeModal',
  initialState,
  reducers: {
    setPaymentTypeModal: (state, action) => {
      state.modalOpen = action.payload;
    },
  },
});
export const { setPaymentTypeModal } = PaymentTypeModal.actions;
export default PaymentTypeModal.reducer;

// [TODO] delete this file after refactor is done and remove the slice
import { createSlice } from '@reduxjs/toolkit';
type TodoState = {
  phone_number: string;
  otp_code: string;
  phone_number_sent: boolean;
  order_number: string;
  order_id: number;
  genereate_otp_status: string;
  generateOTPFields: {
    amount: number;
    currency: string;
    language: string;
  };
};
const initialState: TodoState = {
  phone_number: '',
  otp_code: '',
  phone_number_sent: false,
  order_number: '',
  order_id: 0,
  genereate_otp_status: '',
  generateOTPFields: {
    amount: 0,
    currency: '',
    language: '',
  },
};
export const STCPay = createSlice({
  name: 'STC Pay',
  initialState,
  reducers: {
    setSTCPhoneNumber: (state, action) => {
      state.phone_number = action.payload;
    },
    setSTCOtpCode: (state, action) => {
      state.otp_code = action.payload;
    },
    setPhoneNumberSent: (state, action) => {
      state.phone_number_sent = action.payload;
    },
    setOrderNumber: (state, action) => {
      state.order_number = action.payload;
    },
    setOrderId: (state, action) => {
      state.order_id = action.payload;
    },
    setGenerateOrderStatus: (state, action) => {
      state.genereate_otp_status = action.payload;
    },
    setGenerateOTPFields: (state, action) => {
      state.generateOTPFields = action.payload;
    },
  },
});
export const {
  setSTCPhoneNumber,
  setSTCOtpCode,
  setPhoneNumberSent,
  setOrderNumber,
  setOrderId,
  setGenerateOrderStatus,
  setGenerateOTPFields,
} = STCPay.actions;
export default STCPay.reducer;
